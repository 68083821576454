import logo from './logo.svg';
import './App.css';
import { HomePageContainer } from './HomePageContainer';
import About from './AboutViews/About';
import Credits from './Credits/Credits';
import {
	BrowserRouter as Router,
	Route,
  Routes,
	Link,
	useRouteMatch,
	useParams,
} from "react-router-dom";

function App() {
	return (
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path='/' element={<HomePageContainer/>}></Route>
            <Route path='/dc32e356-67ab-11ed-9022-0242ac120002/about/739d45d9-249a-4a92-973f-0c9b3a5ea66b/largos/about' element={<About/>}></Route>
            <Route path='/dc32e356-67ab-11ed-9022-0242ac120002/credit/739d45d9-249a-4a92-973f-0c9b3a5ea66b/largos/credit' element={<Credits/>}></Route>
          </Routes>
        </header>
      </div>
	);
}

export default App;
