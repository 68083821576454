import { useState, useEffect } from "react";
import './App.css';

export function HomePageContainer() {
	return (	
        <div className="App">
        <header className="App-header">	
		<div className="VStack SU mainContainer">
		  {/* Begining of VStack.SU */}

		  {/* NAV: */}
		  <div className='larogsTopNav'>
			<div className="HStack SU titleContainer">
			  {/* <img className="LargosLogo" src={logo}>largos</img> */}
			  <h1 className="largosTitle">Λαργος</h1>
			</div>
		  </div>
		  
		  <div className="mainContent">
			<div className="HStack-MainContent">
			  <div className="AboutUsContainer">
				<div className="VStack SU aboutContentContainer">
				  <h2>What Is Largos All About</h2>
				  <h3>Largos is an experiment app of the worlds inner conscious</h3>
				  <h4>This App is meant for people to throw out ideas, emotions, conecpts, critiques, share love of things they have on their chest or close to their heart. This App allows for people to take poles on the populus to vote about the world and it goings. It's meant for love, hate, laughter, thought and catharsis, and through that mirror at our own collective being hopefully we can find peace and solice in knowing we are one among many in the struggle against the existential trials of existence.</h4>
				  <div className="AppImageContainer">
					<div></div>
				  </div>
				</div>
			  </div>
			  <div className="ContactUsContainer">
				<div className='formContainer'>
				  <h2>Contact Us</h2>
				  {/* drop down of a why: compliment, complaint, comment */}
				  <form>

					<div className='VStack SU'>
					  <select className='formObjective'>
						<option value="0">compliment</option>
						<option value="1">complaint</option>
						<option value="2">comment</option>
					  </select>
					  <div className='HStack SU InputTags'>
						<label for="name">Enter your name:</label>
						<input type="name" name="name" placeholder='John Doe'></input>
					  </div>
					  <div className='HStack SU InputTags'>
						<label for="email">Enter your email:</label>
						<input type="email" id="email" name="email" placeholder='email@email.com'></input>
					  </div>
					</div>
					<textarea></textarea>
					<div className='ContactUsSubmitContainer'>
					  <button className="SubmitButton" type="submit">Submit</button>
					</div>
				  </form>
				</div>
			  </div>
			</div>
		  </div>

		  <div className="secondary-content">
            <div classNames="AppInfoContianer"></div>
          </div>
		  {/* End of VStack.SU */}
		</div>
                </header>
      </div>
	)
}