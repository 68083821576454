import React from "react";
import './Credits.css'
import backgroundImage from './creditBackground.jpg'
import logo from './favicon.ico'

export default function Credits() {
    return (
        <div className="CreditsPageContainer">
            <div className="backgroundImageContainer">
                <img className="backgroundImage" src={backgroundImage}/>
            </div>
            <div className="CreditsPage">
                <div className='Credits-larogs-Top-Nav'>
                    <div className="HStack SU titleContainer">
                        <h1 className="Credits-Largos-Title">Λαργος</h1>
                    </div>
                </div>
                <div className='Credits-larogs-Top-Nav'>
                    <div className="HStack SU titleContainer">
                        <h1 className="CreditsPageTitle">Credits</h1>
                    </div>
                </div>
                <div className='Credits-larogs-Top-Nav'>
                    <div className="HStack SU titleContainer">
                        <h4 className="CreditsTitle">Icon Attribution</h4>
                    </div>
                </div>
                <ul className="CreditsListContainer">
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/world" title="world icons">World icons created by Freepik - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/pray" title="pray icons">Pray icons created by Freepik - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/emoji" title="emoji icons">Emoji icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/nerd" title="nerd icons">Nerd icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/emoji" title="emoji icons">Emoji icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/emoji" title="emoji icons">Emoji icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/emoji" title="emoji icons">Emoji icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/sweat" title="sweat icons">Sweat icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/emoji" title="emoji icons">Emoji icons created by Freepik - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/like" title="like icons">Like icons created by Freepik - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/like" title="like icons">Like icons created by Freepik - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/save" title="save icons">Save icons created by inkubators - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/bookmark" title="bookmark icons">Bookmark icons created by inkubators - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/user" title="user icons">User icons created by Freepik - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/reply" title="reply icons">Reply icons created by Bharat Icons - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/adjust" title="adjust icons">Adjust icons created by dmitri13 - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/bell" title="bell icons">Bell icons created by Stockio - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/emoji" title="emoji icons">Emoji icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/sad" title="sad icons">Sad icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/bored" title="bored icons">Bored icons created by Vectors Market - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/notification" title="notification icons">Notification icons created by ghufronagustian - Flaticon</a></li>
                    <li><a className="creditsLinksA" href="https://www.flaticon.com/free-icons/message" title="message icons">Message icons created by Freepik - Flaticon</a></li>
                </ul>
                <div className='Credits-larogs-Top-Nav'>
                    <div className="HStack SU titleContainer">
                        <h4 className="CreditsTitle">Lirbary Credits</h4>
                    </div>
                </div>
                <ul className="CreditsListContainer">
                    <li><a className="creditsLinksA" href="https://github.com/pikachu987/Tags" title="message icons">Tags</a></li>
                    <li><a className="creditsLinksA" href="https://github.com/MessageKit/MessageKit" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="https://github.com/airbnb/lottie-ios" title="message icons">Lottie-IOS</a></li>
                    <li><a className="creditsLinksA" href="https://github.com/HeroTransitions/Hero" title="message icons">HeroTransitions-Hero</a></li>
                    <li><a className="creditsLinksA" href="https://github.com/firebase/" title="message icons">Firebase</a></li>
                    <li><a className="creditsLinksA" href="https://firebase.google.com/docs/admob" title="message icons">Google Mobile Ads</a></li>
                    <li><a className="creditsLinksA" href="https://github.com/google/GoogleSignIn-iOS" title="message icons">Google Sign In</a></li>
                    {/* <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li> */}
                </ul>
                <div className='Credits-larogs-Top-Nav'>
                    <div className="HStack SU titleContainer">
                        <h4 className="CreditsTitle">Photo & Art Credits</h4>
                    </div>
                </div>
                <ul className="CreditsListContainer">
                    <li><a className="creditsLinksA" href="https://www.pexels.com/photo/snow-covered-mountain-11300164/" title="message icons">Photo by Önder Örtel:</a></li>
                    {/* <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li>
                    <li><a className="creditsLinksA" href="" title="message icons">MessageKit</a></li> */}
                </ul>
                <div className="spacerDiv" style={{height : '100px',width : '100vw', backgroundColor : 'transparent'}}></div>

                <div className='Credits-larogs-Top-Nav'>
                    <div className="HStack SU titleContainer">
                        <h4 className="CreditsTitle" style={{width : 'auto', height: 'auto'}}>Thanks all for help producing Largos! ❤️</h4>
                        <img className="creditsAppLogo" src={logo}/>
                    </div>
                </div>

                <div className="spacerDiv" style={{height : '100px',width : '100vw', backgroundColor : 'transparent'}}></div>
            </div>   
        </div>     
    )
}